import { Injectable } from "@angular/core";
import { AuthService } from "../../auth0/services/auth.service";
import { LetsMFAService } from 'lets-mfa-angular';
import { filter, take } from "rxjs";
import { UserService } from "../../auth0/services/user.service";

@Injectable({
  providedIn: 'root'
})
export class TokenValidatorService {

  private FIVE_MINUTES_IN_MS = 300_000;

  constructor(
    private authService: AuthService,
    private letMfaService: LetsMFAService,
    private userService: UserService,
  ) {
  }

  public trackMfaState(): void {
    this.userService.currentUser$
    .pipe(
      filter((user) => user != null),
      take(1)
    )
    .subscribe((user) => {
      if (user.sub && user.sub.startsWith('auth0')) {
        this.checkValidationState();
      }
    });
  }

  private checkValidationState(): void {
    const intervalId = setInterval(() => {
      this.letMfaService.checkValidationState()
        .pipe(take(1))
        .subscribe({
          next: status => {
            if (status.validationState != "validated") {
              clearInterval(intervalId);
              this.authService.logout().then();
            }
          },
          error: () => {
            clearInterval(intervalId);
            this.authService.logout().then();
          }
        });
    }, this.FIVE_MINUTES_IN_MS);
  }

}
